import abbottAndApi from "@/apis/liff/v2/proj/abbott-and";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      queryData: "projAbbottAnd/queryData",
      couponType: "projAbbottAnd/couponType",
    }),
  },
  methods: {
    formatQueryData() {
      let query = this.queryData;
      switch (this.couponType) {
        case "j5":
        case "j5-1":
          query.coupon_code = 'WI_001'
          break;
        default:
          break;
      }
      return query
    },
    async checkStoreCode() {
      try {
        const { data } = await abbottAndApi.checkStoreCode(this.queryData.store_code)
        return data.is_exist
      } catch (e) {
        alert("店家代碼錯誤");
        return false
      }
    },
    async validate() {
      switch (this.couponType) {
        case "j4":
          return this.checkStoreCode();
        default:
          return true;
      }
    },
    async submit(lineId) {
      if (!this.validate()) {
        return;
      }
      const query = this.formatQueryData();
      try {
        await abbottAndApi.sendCoupon(
          this.$route.params.orgCode,
          {
            ...query,
            line_id: lineId,
          }
        )
        this.$router.push({
          name: 'LiffProjAbbottAndThanks',
          query: {
            messages: "成功領取",
          },
        });
      } catch (e) {
        this.$router.push({
          name: 'LiffProjAbbottAndThanks',
          query: {
            messages: e.response.data.message,
          },
        });
      }
    },
    async checkMember(lineId) {
      switch (this.couponType) {
        case "j4":
        case "j5":
        case "j5-1":
          try {
            const res = await abbottAndApi.checkMember(
              this.$route.params.orgCode,
              {
                line_id: lineId,
              }
            );
            if (!res.data.is_member) {
              this.$router.push({
                name: "LiffRegisterIndex",
                params: {
                  orgCode: this.$route.params.orgCode,
                },
                query: {
                  redirect_action: this.$route.query.redirect_action ?? "proj.abbott_and.branch_picker",
                  redirect_button: this.$route.query.redirect_button ?? 1,
                  success_button: this.$route.query.success_button ?? 0,
                }
              });
            } else {
              await abbottAndApi.registerCustomer(this.$route.params.orgCode, {
                line_id: lineId
              });

              const res = await abbottAndApi.checkMember(
                this.$route.params.orgCode,
                {
                  line_id: lineId,
                }
              );

              if (res.data.is_bind_store) {
                this.$router.push({
                  name: 'LiffProjAbbottAndSendCoupon'
                })
              } else {
                this.$router.push({
                  name: 'LiffProjAbbottAndBranchPicker'
                })
              }
            }
          } catch (e) {
            console.log(e)
            alert("資料有錯請洽客服");
          }
          break;
        default:
          try {
            const res = await abbottAndApi.checkMember(
              this.$route.params.orgCode,
              {
                phone: this.queryData.phone,
              }
            );
            if (res.data.is_member) {
              if (res.data.line_id == lineId) {
                this.$router.push({
                  name: 'LiffProjAbbottAndSendCoupon'
                })
              } else {
                alert("此手機與查詢會員不一致，請聯絡客服人員");
              }
            } else {
              this.$router.push({
                name: 'LiffProjAbbottAndSendCoupon'
              })
            }
          } catch (e) {
            console.log(e.response.data.message);
            alert("資料有錯請洽客服");
          }
          break;
      }
    },
  },
};
